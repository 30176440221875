import { Button, Collapse } from 'antd';
import {
  IoAddCircleOutline,
  IoCaretDown,
  IoCaretForward,
  IoChevronBack,
  IoChevronDown,
  IoChevronForward,
} from 'react-icons/io5';

import React from 'react';
import { ScHeader3 } from './../components/styled-components';
import globals from '../utils/variables';
import styled from 'styled-components';

const ScContainer = styled.div`
  padding-top: 1.5rem;
  /* background: #666; */
  flex: 1;

  .ant-collapse-item {
    background: ${globals.secondaryColorTransparent};
  }
  .ant-collapse-header {
    color: ${globals.mainColor} !important;
  }
`;

const ScAccordionItem = styled.div`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  transition: all 0.5s;

  &:hover {
    cursor: pointer;
    border: 1px solid ${globals.mainColor};
  }
`;
const AccordionItem = ({ title }) => {
  return (
    <ScAccordionItem>
      <div
        style={{
          margin: '0.25rem',
          display: 'flex',
          justifyContent: 'center',
          width: '1.75rem',
        }}
      >
        <IoAddCircleOutline size="1.7rem" />
      </div>
      <span style={{ margin: '0 0.25rem' }}>{title}</span>
    </ScAccordionItem>
  );
};

const IndexPage = () => (
  <ScContainer>
    <ScHeader3>Was biete ich an</ScHeader3>
    <Collapse
      accordion
      expandIcon={({ isActive }) =>
        isActive ? (
          <IoChevronDown size="1rem" />
        ) : (
          <IoChevronForward size="1rem" />
        )
      }
    >
      <Collapse.Panel header="Persönlichkeitsberatung"></Collapse.Panel>
      <Collapse.Panel header="Stress und Burnout-Prävention"></Collapse.Panel>
      <Collapse.Panel header="Kommunikationsberatung"></Collapse.Panel>
      <Collapse.Panel header="Konfliktmanagement"></Collapse.Panel>
      <Collapse.Panel header="Familien- und Erziehungsberatung"></Collapse.Panel>
      <Collapse.Panel header="Partnerschaft- und Eheberatung"></Collapse.Panel>
      <Collapse.Panel header="Umgang mit Krankheit, Abschied und Tod"></Collapse.Panel>
      <Collapse.Panel
        header="Krisenberatung (Überforderung, Mobbing, Verlust des Arbeitsplatzes,
      Belastungen durch Krankheiten)"
      ></Collapse.Panel>
      <Collapse.Panel header="Meditation"></Collapse.Panel>
      <Collapse.Panel header="Resilienz und Achtsamkeitstraining"></Collapse.Panel>
    </Collapse>

    {/* <ul> */}
    {/* </ul> */}
  </ScContainer>
);

export default IndexPage;
