import React from 'react';
import { ScHeader3 } from './../components/styled-components';
import globals from '../utils/variables';
import styled from 'styled-components';

const ScContainer = styled.div`
  padding-top: 1.5rem;
  /* background: #666; */
  flex: 1;
`;

const ScTimeline = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;

const ScTimelineItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
`;

const ScTimelineDateItem = styled.div`
  position: relative;
  /* border-right: 1px solid #ddd; */
  /* margin-right: 1rem; */
  padding-right: 1rem;
  min-width: 6rem;
  text-align: right;
  height: auto;

  &::after {
    --size: 10px;
    content: '';
    position: absolute;
    background: #fafafa;
    width: var(--size);
    height: var(--size);
    border: 3px solid ${globals.mainColor};
    box-shadow: 0px 0px 0px 6px #fafafa;
    border-radius: 100%;
    right: calc(var(--size) / -2);
    top: calc(50% - var(--size) / 2);
    transition: all 300ms;
  }

  &:hover {
    &::after {
      background: ${globals.mainColor};
      border: 0px solid ${globals.mainColor};
    }
  }
`;
const ScTimelineTextItem = styled.div`
  border-left: 1px solid #ddd;
  /* margin-left: -1px; */

  flex: 1;
  padding: 1rem;
  padding-top: 0;
`;

const IndexPage = () => (
  <ScContainer id="person">
    <ScHeader3>Zur Person</ScHeader3>
    <ScTimeline>
      <ScTimelineItem>
        <ScTimelineDateItem>2022</ScTimelineDateItem>
        <ScTimelineTextItem>
          Ausbildung zur NADA -Therapeutin
        </ScTimelineTextItem>
      </ScTimelineItem>
      <ScTimelineItem>
        <ScTimelineDateItem>2021</ScTimelineDateItem>
        <ScTimelineTextItem>Ausbildung zur Supervision</ScTimelineTextItem>
      </ScTimelineItem>
      <ScTimelineItem>
        <ScTimelineDateItem>2019</ScTimelineDateItem>
        <ScTimelineTextItem>
          Abschluss zur Dipl. Lebens- und Sozialberaterin an der Mentalakademie
          Europa
        </ScTimelineTextItem>
      </ScTimelineItem>
      <ScTimelineItem>
        <ScTimelineDateItem>2018 </ScTimelineDateItem>
        <ScTimelineTextItem>
          Abschluss zum Dipl. Mentalcoach an der Mentalakademie Europa
        </ScTimelineTextItem>
      </ScTimelineItem>
      <ScTimelineItem>
        <ScTimelineDateItem>2016</ScTimelineDateItem>
        <ScTimelineTextItem>
          Abschluss zur Dipl. Mentaltrainerin an der Mentalakademie Europa
        </ScTimelineTextItem>
      </ScTimelineItem>
      <ScTimelineItem>
        <ScTimelineDateItem>1997</ScTimelineDateItem>
        <ScTimelineTextItem>
          Ausbildung zur Dipl. Operationsschwester am LKH Graz
        </ScTimelineTextItem>
      </ScTimelineItem>
      <ScTimelineItem>
        <ScTimelineDateItem>1993-2020</ScTimelineDateItem>
        <ScTimelineTextItem>
          UKH Graz Traumatologie, Orthopädie als Dipl. Operationsschwester tätig
        </ScTimelineTextItem>
      </ScTimelineItem>
      <ScTimelineItem>
        <ScTimelineDateItem>1991-1992</ScTimelineDateItem>
        <ScTimelineTextItem>
          Transplantationschirurgie LKH Graz
        </ScTimelineTextItem>
      </ScTimelineItem>
      <ScTimelineItem>
        <ScTimelineDateItem>1991</ScTimelineDateItem>
        <ScTimelineTextItem>
          Abschluss der Ausbildung zur Dipl. Gesundheits- und Krankenschwester
          am LKH Graz
        </ScTimelineTextItem>
      </ScTimelineItem>
    </ScTimeline>
  </ScContainer>
);

export default IndexPage;
