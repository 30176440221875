import {
  ScBox,
  ScBoxHeader3,
  ScHeader3,
} from './../components/styled-components';

import Image from '../components/image';
import { Link } from 'gatsby';
import React from 'react';

const IndexPage = () => (
  <Link
    to="mentaltraining"
    style={{
      flex: '1',
      position: 'relative',
      marginLeft: '.5rem',
      marginRight: '.5rem',
    }}
  >
    <ScBox>
      <Image
        style={{ width: '100%', height: '19rem', objectFit: 'cover' }}
        src="pictures/pexels-pavel-danilyuk-7776206.jpg"
      />
      <ScBoxHeader3>Mentaltraining</ScBoxHeader3>
    </ScBox>
  </Link>
);

export default IndexPage;
