import React, { useState } from 'react';
import {
  ScFlexColCenter,
  ScMaxWidthContainer,
  ScMaxWidthContainerWide,
} from './../components/styled-components';

import Coaching from '../subpages/coaching';
import Consultation from '../subpages/consultation';
import Image from '../components/image';
import Introduction from '../subpages/introduction';
import LandingArea from '../subpages/landingarea';
import Layout from '../components/layout';
import Map from '../subpages/map';
import Mentaltraining from '../subpages/mentaltraining';
import Nada from '../subpages/nada';
import Person from '../subpages/person';
import SEO from '../components/seo';
import Services from '../subpages/services';
import globals from '../utils/variables';
import styled from 'styled-components';

const ScPersonImage = styled.div`
  height: 22rem;
  width: 30rem;
  max-width: 80vw;
  margin: 1rem;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  overflow: hidden;
`;

const ScColumns = styled.div`
  @media only screen and (min-width: 1024px) {
    display: flex;
  }
`;

let accepted = false;
if (typeof document !== `undefined`) {
  accepted = document.cookie.indexOf('gatsby-gdpr=true') !== -1;
}

const IndexPage = () => {
  const [cookieAccepted, setCookieAccepted] = useState(!!accepted);
  return (
    <Layout onCookieAccept={() => setCookieAccepted(true)}>
      <SEO title="Balance & Effizienz im Alltag" keywords={globals.keywords} />
      <LandingArea />
      <ScMaxWidthContainer>
        <ScColumns>
          <Introduction />
        </ScColumns>

        <ScColumns style={{ paddingBottom: '1rem' }}>
          <Coaching />
          <Consultation />
          <Mentaltraining />
          <Nada />
        </ScColumns>
        <ScColumns>
          <Person />
          <Services />
        </ScColumns>
        <Map cookieAccepted={cookieAccepted} />
      </ScMaxWidthContainer>
    </Layout>
  );
};

export default IndexPage;
