import {
  ScFlexAlignCenter,
  ScFlexCenter,
  ScHeader3,
} from './../components/styled-components';

import { IoWarningOutline } from 'react-icons/io5';
import React from 'react';
import styled from 'styled-components';

const ScIFrame = styled.div`
  border: 0;
  width: 100%;
  height: 40rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 3px;
`;

const IndexPage = ({ cookieAccepted }) => {
  return (
    <div style={{ padding: '1.5rem 0rem' }} id="map">
      <div>
        <ScHeader3>Anfahrt</ScHeader3>
      </div>
      {cookieAccepted ? (
        <ScIFrame>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2715.814089525047!2d15.711075615807967!3d47.10271513064237!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476e51b8184bca81%3A0xf3fd3e781f8a5f72!2sFranz-Josef-Stra%C3%9Fe%2017%2C%208200%20Gleisdorf!5e0!3m2!1sen!2sat!4v1620030471048!5m2!1sen!2sat"
            allowFullScreen=""
            style={{ width: '100%', height: '40rem', border: '0' }}
            loading="lazy"
          ></iframe>
        </ScIFrame>
      ) : (
        <ScFlexAlignCenter>
          <IoWarningOutline size="1.5em" />
          &nbsp; Sie müssen Cookies akzeptieren um die eingebettete Karte von
          Google Maps sehen zu können
        </ScFlexAlignCenter>
      )}
    </div>
  );
};

export default IndexPage;
