import { IoCall, IoMail, IoMap } from 'react-icons/io5';
import {
  ScLogo,
  ScMaxWidthContainer,
  ScMaxWidthContainerWide,
} from './../components/styled-components';

import Image from '../components/image';
import React from 'react';
import globals from '../utils/variables';
import styled from 'styled-components';

const ScIntroContainer = styled.div`
  position: relative;
  height: 550px;
  overflow: hidden;
  margin: auto;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  @media (max-width: 500px) {
    height: 275px;
  }
  @media (min-width: 501px) and (max-width: 1000px) {
    height: 375px;
  }
  @media (min-width: 1001px) and (max-width: 1500px) {
    height: 425px;
  }
`;

const ScButtonContainer = styled.div`
  position: relative;
  z-index: 100;
  top: 0;
  width: 100%;
  display: flex;
  text-align: center;
  margin-bottom: 1rem;
`;

const ScCTAButton = styled.a`
  padding: 0.35rem;
  padding-left: 1rem;
  color: #333;
  background: transparent;
  display: flex;
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
  align-items: center;
  /* justify-content: space-between; */
  flex: 1;
  flex-basis: 100px;
  flex-direction: column;
  cursor: pointer;

  @media (max-width: 1000px) {
    font-size: 0;
  }

  @media (min-width: 1001px) {
    &:hover {
      background: #ddd;
      color: #222;
      font-weight: bold;
    }
  }
  @media (max-width: 600px) {
    border: none;
    padding-bottom: 0;
  }

  &:hover .ScIcon {
    border: 4px solid ${globals.mainColor};
    background: ${globals.mainColor};
    color: #fff;
  }
`;
const ScIcon = styled.div`
  --ggs: 1.5;
  position: relative;
  border-radius: 100%;
  top: -3rem;
  margin-bottom: -2rem;
  background: #fff;
  border: 3px solid ${globals.mainColor};
  color: ${globals.mainColor};
  height: 5rem;
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    border: 4px solid ${globals.mainColor};
    background: ${globals.mainColor};
    color: #fff;
  }
  @media (max-width: 420px) {
    height: 4rem;
    width: 4rem;
    top: -2.5rem;
  }
`;

const ScPanel = styled.div`
  /* position: absolute; */
  color: ${globals.mainColor};
  width: 45%;
  margin-left: 55%;
  bottom: 2.5rem;
  right: 0;
  padding-right: 0.5rem;
  z-index: 110;
  text-shadow: 1px 1px 1px #fff;

  @media (min-width: 1501px) {
    margin-top: 3rem;
  }
  @media (max-width: 1000px) {
    width: 35%;
    margin-left: 65%;
  }
  /* @media (max-width: 500px) {
    width: 30%;
    margin-left: 70%;
  } */
  @media (max-width: 600px) {
    display: none;
  }
`;

const ScPanelMobile = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    color: ${globals.mainColor};
    background: ${globals.secondaryColorTransparent};
    border-radius: 5px;
    margin-bottom: 2rem;
  }
`;

const ScOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  @media (max-width: 600px) {
    display: none;
  }

  &::before {
    content: '';
    position: absolute;
    right: 0;
    background: linear-gradient(
      -90deg,
      rgba(250, 250, 250, 1) 0%,
      rgba(250, 250, 250, 1) 80%,
      rgba(250, 250, 250, 0) 100%
    );
    width: 55%;
    height: 100%;

    /* @media (max-width: 600px) {
      width: 45%;
    } */
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 90%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(207, 203, 182, 1) 0%,
      rgba(207, 203, 182, 1) 50%,
      rgba(250, 250, 250, 0) 100%
    );

    @media (max-width: 1000px) {
      display: none;
    }

    @media (min-width: 1501px) {
      right: 80%;
    }
  }
`;

const ScImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0%;
  width: 85%;
  z-index: -1;
  object-fit: cover;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.12), 0 3px 2px rgba(0, 0, 0, 0.24);

  @media (min-width: 1001px) {
    left: 5%;
    width: 60%;
  }
  @media (min-width: 1501px) {
    left: 10%;
    width: 50%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const ScBlackContainer = styled.div`
  /* background: #666; */
  /* max-width: 1280px; */
  /* box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.12),
    inset 0 1px 2px rgba(0, 0, 0, 0.24); */
`;

const ScQuote = styled.div`
  /* font-family: 'PoiretOne'; */
  font-size: 8.5pt;

  @media (max-width: 500px) {
    font-size: 8pt;
  }
  @media (max-width: 1000px) {
    display: none;
  }
`;

const ScInfo = styled.div`
  /* font-family: 'PoiretOne'; */
  font-size: 110%;
  margin-bottom: 1rem;

  @media (max-width: 1000px) {
    margin-bottom: 1rem;
    font-size: 11pt;
  }
  @media (max-width: 600px) {
    display: none;
  }
`;
const ScInfoMobile = styled.div`
  /* font-family: 'PoiretOne'; */
  /* display: none; */
  font-size: 110%;
  text-align: center;
  border-top: 1px ${globals.mainColor} solid;
  padding-top: 0.75rem;
  padding-bottom: 0.5rem;
`;

const ScSlogan = styled.div`
  font-size: 30pt;
  font-family: 'RetroSignature';
  padding: 0.5rem 0rem;
  margin-bottom: 1.5rem;
  line-height: 110%;

  strong {
    font-family: 'RetroSignature';

    font-weight: bold;
    font-size: 100%;
  }

  @media (max-width: 1000px) {
    font-size: 25pt;
  }
  /* @media (max-width: 500px) {
    font-size: 20pt;
    margin-bottom: 0.5rem;
  } */
  @media (max-width: 600px) {
    font-size: 24pt;
    margin-bottom: 0.5rem;
  }
`;

export default () => (
  <div>
    <ScIntroContainer>
      <ScMaxWidthContainer>
        <ScPanel>
          <ScLogo />
          <ScSlogan>
            <strong>B</strong>alance und
            <br />
            <strong>E</strong>ffizienz im
            <br />
            <strong>A</strong>lltag
            <br />
          </ScSlogan>
          <ScInfo>
            <strong>Sabine Pölzl</strong> <br />
            <strong>Dipl. Mental-Coach und Mental-Trainerin</strong>
            <br />
            <strong>Dipl. Lebens- und Sozialberaterin</strong>
          </ScInfo>
          <ScQuote>
            <div style={{ fontSize: '110%' }}>
              Auch aus Steinen, die dir in den Weg gelegt werden,
              <br />
              kannst du etwas Schönes bauen.
              <br />
            </div>
            <figcaption style={{}}>
              <br />
              &mdash; <cite style={{}}>Erich Kästner</cite>
            </figcaption>
          </ScQuote>
        </ScPanel>
      </ScMaxWidthContainer>
      <ScOverlay />
      <ScImage>
        {/* <Image src="pictures/pexels-jess-bailey-designs-768474.jpg" /> */}
        <Image
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          src="pictures/KS2R9292-2.jpg"
        />
      </ScImage>
    </ScIntroContainer>
    <ScBlackContainer>
      <ScMaxWidthContainer>
        <ScButtonContainer>
          <ScCTAButton href="tel:+43 676 377 09 27">
            <ScIcon className="ScIcon">
              <IoCall size="2rem" />
            </ScIcon>
            +43 676 377 09 27
          </ScCTAButton>
          <ScCTAButton href="mailto:bea@lifebalance-coach.at">
            <ScIcon className="ScIcon">
              <IoMail size="2rem" />
            </ScIcon>
            bea@lifebalance-coach.at
          </ScCTAButton>
          <ScCTAButton href="https://goo.gl/maps/CosGpdbaWSVBFNxa7">
            <ScIcon className="ScIcon">
              <IoMap size="2rem" />
            </ScIcon>
            Franz-Josef-Straße 17 <br />
            8200 Gleisdorf
          </ScCTAButton>
        </ScButtonContainer>
        <ScPanelMobile>
          <ScLogo className="logo" />
          <ScSlogan>
            <strong>B</strong>alance und&nbsp;
            <strong>E</strong>ffizienz im&nbsp;
            <strong>A</strong>lltag&nbsp;
          </ScSlogan>
          <ScInfoMobile>
            <strong>Sabine Pölzl</strong> <br />
            <strong>Dipl. Mental-Coach und Mental-Trainerin</strong>
            <br />
            <strong>Dipl. Lebens- und Sozialberaterin</strong>
          </ScInfoMobile>
        </ScPanelMobile>
      </ScMaxWidthContainer>
    </ScBlackContainer>
  </div>
);
