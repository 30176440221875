import React from 'react';
import { ScHeader3 } from '../components/styled-components';
import globals from '../utils/variables';
import styled from 'styled-components';

const ScContainer = styled.div`
  /* padding: 1.5rem; */
  /* color: #fff; */
  flex: 1;
  max-width: 900px;
`;

const IndexPage = () => (
  <ScContainer>
    <ScHeader3>Eine gute Work Life Balance ist möglich!</ScHeader3>
    <p>
      Durch mentale Methoden zu mehr Glück und einem ausgeglichenen Leben
      finden. In unserer schnelllebigen Zeit nimmt Stress immer mehr an
      Bedeutung zu und Zeit wird als Mangelware empfunden.
    </p>
    <p>
      Wir versuchen uns anzupassen um immer schneller und besser zu werden.
      Dieses Gefühl führt zu Hektik und ist auch ein Nährboden für Krankheiten
      und Störungen.
    </p>
    <p>
      Eingefahrene Muster müssen manchmal unterbrochen werden, um Energieräuber
      zu erkennen und dagegen zu wirken. Umso entscheidender ist es den
      richtigen Ausgleich zu finden. So können Körper, Geist und Seele in
      Einklang bleiben.
    </p>
    <p>
      Die psychologische Beratung unterstützt Menschen in schwierigen
      Lebenssituationen. Sie hilft Entscheidungen zu treffen und soll Menschen
      dazu befähigen, wieder selbstständig die Hürden des Lebens zu meistern.
    </p>
    <p>
      <strong
        style={{
          position: 'relative',
          color: globals.mainColor,
          fontFamily: 'AR Bonnie',
          fontSize: '150%',
          bottom: '-2px',
        }}
      >
        BEA
      </strong>{' '}
      steht für Balance und Effizienz im Alltag
    </p>
  </ScContainer>
);

export default IndexPage;
